<template>
	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
	<Alert v-if="showDeleteAlert" :closeLabel="'取消'" :submitLabel="'確認'" @close="showDeleteAlert = false" @submit="deleteCustomerAddress($route.query.index)">
		<div class="pb-5 text-center text-xl">移除資料後不能復原，確定要移除嗎？</div>
	</Alert>

	<BaseLayout v-if="address">
		<Modal v-if="showAreaCodeModal" @close="showAreaCodeModal = false" :title="$t('location.locationSearch')">
			<template v-slot:search>
				<div class="absolute top-20 w-full">
					<Search>
						<input v-model="inputAreaCode" type="text" class="ml-2 w-full bg-transparent text-sm" :placeholder="$t('location.locationSearch')" />
					</Search>
				</div>
			</template>
			<div class="pt-16">
				<div class="flex h-full flex-col divide-y overflow-x-scroll">
					<div v-for="country in searchAreaCode" :key="country" class="flex justify-between py-2" :class="country.dial_code == selectedAreaCode ? 'bg-primary text-white' : null">
						<label :for="country.dial_code" class="whitespace-nowrap py-1 px-5 text-lg">
							{{
								`${country.name}
							（${country.dial_code}）`
							}}</label
						>
						<input class="w-full appearance-none" v-model="selectAreaCode" :id="country.dial_code" :value="country.dial_code" :checked="country.dial_code == selectedAreaCode" type="radio" :name="country" @change=";(selectedAreaCode = selectAreaCode), (showAreaCodeModal = false)" />
					</div>
				</div>
			</div>
		</Modal>

		<div v-else>
			<Toolbar @clickLeft="isLoading ? null : $router.go(-1)" @clickRight="showDeleteAlert = true">
				<template v-slot:start>
					<Icon class="h-5 w-5" icon="angleLeft" />
				</template>
				<p>{{ $t('createAddress.deliveryAddress') }}</p>
				<template v-if="$route.query.index && !isLoading" v-slot:end>
					<div class="flex items-center rounded-xl bg-red-500 px-2 py-0.5 text-white">
						<Icon class="h-5 w-5" icon="xmark" />
						<p>
							{{ $t('createAddress.deleteAddress') }}
						</p>
					</div>
				</template>
			</Toolbar>
			<form @submit.stop.prevent="submit" class="flex flex-col space-y-3 px-5 text-lg">
				<List :title="$t('createAddress.receiverInfo')">
					<list-item :title="$t('createAddress.receiverName')">
						<input v-model="address.firstName" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('createAddress.addReceiverName')" />
					</list-item>
					<list-item :title="$t('createAddress.receiverSurname')">
						<input v-model="address.lastName" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('createAddress.addReceiverSurname')" />
					</list-item>
					<list-item :title="$t('profile.billingAddressAreaCode')">
						<button @click="showAreaCodeModal = true" class="inline-flex items-center space-x-1">
							<p class="text-md whitespace-nowrap">{{ selectedAreaCode ? selectedAreaCode : $t('signin.countryCode') + $t('signin.countryCode2') }}</p>
							<Icon class="h-3 w-3" icon="angleDown" />
						</button>
					</list-item>

					<list-item :title="$t('createAddress.contactNumber')">
						<input v-model="selectedPhoneNumber" type="tel" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('createAddress.addContactNumber')" />
					</list-item>
					<list-item :title="$t('createAddress.addressType')">
						<select v-model="address.type" class="w-full appearance-none bg-transparent text-right" dir="rtl">
							<option value="" disabled>{{ $t('createAddress.selectAdressType') }}</option>
							<option value="HOME">{{ $t('createAddress.HomeAddress') }}</option>
							<option value="HOTEL">{{ $t('createAddress.HotelAddress') }}</option>
							<option value="OFFICE">{{ $t('createAddress.OfficeAddress') }}</option>
							<option value="OTHER">{{ $t('createAddress.otherAddress') }}</option>
						</select>
					</list-item>
					<list-item :title="$t('createAddress.address')">
						<input v-model="address.addressLine1" class="w-full bg-transparent text-right placeholder-muted" :placeholder="$t('createAddress.addAddress')" required />
					</list-item>
				</List>
				<Footer class="-mx-5">
					<Button type="submit" :loading="isLoading" :disabled="isLoading">{{ $t('createAddress.submitAddress') }}</Button>
					<!-- <Button v-if="$route.query.index && !isLoading" :loading="isLoading" variant="danger" :disabled="isLoading" @click="deleteCustomerAddress">{{ $t('createAddress.deleteAddress') }}</Button> -->
				</Footer>
			</form>
		</div>
	</BaseLayout>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CustomerAddress } from '@/models/customer'
import { useI18n } from 'vue-i18n'
import { useSharedStore } from '@/store/shared'
import { useCustomerStore } from '@/store/customer'
import { parsePhoneNumber } from 'awesome-phonenumber'

export default {
	setup() {
		const route = useRoute()
		const router = useRouter()
		const store = useStore()
		const { config } = storeToRefs(useSharedStore())
		const { currentAddress } = storeToRefs(useCustomerStore())
		const { createAddress, updateAddress, deleteAddress } = useCustomerStore()

		const { locale } = useI18n()
		const countryLists = config.value.countryCode
		const inputAreaCode = ref('')
		const showAreaCodeModal = ref(false)
		const id = route.params.id
		const address = ref(null)
		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)
		const showDeleteAlert = ref(false)
		const selectAreaCode = ref()
		const selectedAreaCode = ref()
		const selectedPhoneNumber = ref()

		onMounted(async () => {
			if (id === 'create') return (address.value = CustomerAddress())
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				address.value = currentAddress.value
				const pn = parsePhoneNumber(address.value.phoneNumber)
				console.log(pn)
				selectedAreaCode.value = `+${pn.countryCode}`
				selectedPhoneNumber.value = `${pn.number.significant}`

				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		})

		const searchAreaCode = computed(() => {
			return countryLists[locale.value].filter(item => {
				const fields = ['name', 'code', 'dial_code']
				let flag = false
				fields.forEach(field => {
					if (item[field].toUpperCase().includes(inputAreaCode.value.toUpperCase())) flag = true
				})
				if (flag) return item
			})
		})
		function showArea() {
			console.log('showArea')
		}
		async function submit() {
			address.value.phoneNumber = selectedAreaCode.value + selectedPhoneNumber.value
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				if (route.query.index) {
					updateAddress(address.value, route.query.index)
				} else {
					createAddress(address.value)
				}
				// await store.dispatch(id === 'create' ? 'createAddress' : 'updateAddress', { address: address.value })

				store.commit('setLoadingState', { loadingState: false })
				router.push(`/account/addresses`)
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function deleteCustomerAddress(index) {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				deleteAddress(index)
				showDeleteAlert.value = false
				// await store.dispatch('deleteAddress', { address: address.value })

				store.commit('setLoadingState', { loadingState: false })
				router.push(`/account/addresses`)
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		return {
			selectAreaCode,
			selectedAreaCode,
			selectedPhoneNumber,
			showArea,
			deleteCustomerAddress,
			config,
			address,
			error,
			isLoading,
			submit,
			deleteAddress,
			countryLists,
			searchAreaCode,
			showAreaCodeModal,
			inputAreaCode,
			showDeleteAlert,
		}
	},
}
</script>
